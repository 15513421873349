<template>
  <div class="w-75 m-auto">
    <nav class="navbar navbar-expand-lg">
      <div class="navbar-collapse">
        <div class="navbar-nav mr-auto">
          <router-link :to="{ name: 'dashboard' }" class="nav-link t-20">
            <h3>Tableau de bord</h3>
          </router-link>
        </div>
      </div>
    </nav>
    <hr style="margin-top: 10px; border-top: 2px solid lightgray"/>
  </div>
  <div class="container margin-boat">
    <div class="row mb-2">
      <div class="col">
        <h4 class="display-6 purple">Bienvenue {{ user }}</h4>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <div class="card col-12 mb-3">
          <div class="card-header-white card-header bold">
            Contacts KAM
          </div>
          <div class="card-body row">
            <div class="col-12 text-center mt-1">
              <div class="row justify-content-center m-2">
                <div class="col-3">
                  <img src="https://alumni.engineering.utoronto.ca/files/2022/05/Avatar-Placeholder-400x400-1.jpg" class="img-rounded-avatar">
                </div>
                <div class="avatar-name col-8">
                  <p style="">Relation Partenaire</p>
                </div>
              </div>
              <div class="mt-3">
                <p>
                  <fa icon="phone" class="mr-2"></fa>
                  04 27 24 00 39
                </p>
                <p>
                  <fa icon="envelope" class="mr-2"></fa>
                  relationpartenaire.kam@koesio.com
                </p>
              </div>
            </div>
            <hr style="width: 100%;">
            <div class="col-12 text-center mt-1">
              <div class="row justify-content-center m-2">
                <div class="col-3">
                  <img src="https://alumni.engineering.utoronto.ca/files/2022/05/Avatar-Placeholder-400x400-1.jpg" class="img-rounded-avatar">
                </div>
                <div class="avatar-name col-8">
                  <p style="">Relation Client</p>
                </div>
              </div>
              <div class="mt-4">
                <p>
                  <fa icon="phone" class="mr-2"></fa>
                  04 75 78 80 47
                </p>
                <p>
                  <fa icon="envelope" class="mr-2"></fa>
                  relationclient.kam@koesio.com
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class=" col-12 overflow-hidden rounded bg-success text-white px-4 py-3 shadow">
          <dt class="text-truncate display-6 font-medium text-white text-center">Taux d'accord de financement</dt>
          <p class="small text-center tracking-tight text-white">(Trois derniers mois)</p>
          <dd v-if="parseInt(accordFinancementCount) !== -1" class="mt-1 text-center h4 font-weight-bold tracking-tight text-white">
            {{ accordFinancementCount }}
            <fa icon="handshake" class="pl-3" size="lg"></fa>
          </dd>
          <div class="no-data" v-else-if="parseInt(accordFinancementCount) === -1">
            Aucune donnée
          </div>
          <div v-else class="d-flex justify-content-center">
            <div class="spinner-border text-white text-center" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="card col-12">
          <div class="card-header card-header-none bold">
            Actions à mener
          </div>
          <div class="card-body">
            <div>
              <div class="progress-steps">
                <template v-for="(data, statusKey) in simulationData" v-bind:key="data">
                  <div class="complete">
                    <h1>
                      <template v-if="totalStatusDisplay(data) > 0">
                        <span :class="{'leaser-color': data.overrideColor}" class="number"><fa icon="ellipsis-h"></fa></span>
                      </template>
                      <template v-else>
                        <span style="background-color: #1da142 !important" class="number"><fa icon="check"></fa></span>
                      </template>
                      <strong class="margin-20">{{ category_key[statusKey] }}</strong>
                    </h1>
                    <template v-if="totalStatusDisplay(data) > 0">
                      <template v-bind:key="value.query" v-for="(value, key) in data.total_by_status">
                        <ul v-if="value !== 0">
                          <li v-if="!doNotDisplay.includes(data.status_langs[key])">
                            <router-link :class="{'disable-click': data.notClickable}" :to="{ name: 'portfolio', query: { filter: `${[statusKey]}:${key}`}}">{{ data.status_langs[key] }}  ({{ value }})</router-link>
                          </li>
                        </ul>
                      </template>
                    </template>
                    <template v-else>
                      <ul>
                        <li style="list-style-type: none;">
                          <span><i>Aucune action à mener</i></span>
                        </li>
                      </ul>
                    </template>

                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>

      </div>
      <div class="col">
        <div class="card col-12 mb-4" style="background-color: rgba(149, 105, 171, 0.1);">
          <div class="card-header-none card-header bold">
            FIL D'INFO KAM
          </div>
          <div class="card-body ">
            {{ dailyMessage }}
          </div>
        </div>
        <div class="card col-12">
          <div style="text-align: center;" class="card-header card-header-none bold mt-1">
            Simulation par statut
            <p style="text-align: center;">(Trois derniers mois)</p>
          </div>
          <div class="card-body">
            <PieChart v-if="chartData.datasets.length" :options="donutsOption" :chartData="chartData"/>
            <div v-else class="d-flex justify-content-center">
              <div class="spinner-grow text-success" style="width: 10rem; height: 10rem" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="ocean">
    <div class="wave"></div>
    <div class="wave"></div>
    <div class="wave"></div>
  </div>
  <div class="oceanbottom">
  </div>
  <div class="boat-wrapper">
    <img class="boat" src="/bateau.svg">
  </div>
</template>

<script>
import {PieChart} from 'vue-chart-3';
import {Chart, registerables} from 'chart.js';
import fundingService from '../services/funding.service';
import {percentage} from '@/helpers/tools';
import UploadService from '@/services/compliance.service';
import { reverseDateFormat } from "@/helpers/tools";

Chart.register(...registerables);
export default {
  name: 'Dashboard',
  components: {PieChart},
  data() {
    return {
      firstDay : null,
      lastDay: null,
      show: false,
      simulationData: null,
      lastSemesterSimulationData: null,
      doNotDisplay: [
        'Accord',
        'Sans suite',
        'Refus',
        'Brouillon',
        'Signé',
        'Refusé',
        'Financée',
        'Annulé',
        'Expirée',
        'Conforme N2',
        'Sans suite',
        'Terminée',
        'En attente signature leaser'
      ],
      donutsOption: {
        plugins: {
          legend: {
            position: 'bottom'
          }
        }
      },
      chartData: {
        labels: ['Etude', 'Réfusée', 'Sans Suite', 'Accord'],
        datasets: []
      },
      category_key: {
        'simulation_accord' : 'Simulation',
        'simulation_contrat' : 'Contrat',
        'simulation_delivery_report' : 'PV',
        'compliances': 'Conformité'
      },
      complianceStats: null
    };
  },
  computed: {
    simulationAccord() {
      const simulationAccordTotal = this.lastSemesterSimulationData.simulation_accord.total_by_status;
      const accord = simulationAccordTotal.OK + simulationAccordTotal.OK_FIN;
      const wait = simulationAccordTotal.WAIT + simulationAccordTotal.WAIT_LEASER + simulationAccordTotal.WAIT_SELLER + simulationAccordTotal.WAIT_MODIF + simulationAccordTotal.WAIT_AP;
      const sansSuite = simulationAccordTotal.SS;
      const refused = simulationAccordTotal.KO;
      return [wait, refused, sansSuite, accord];
    },
    accordFinancementCount() {
      const totalByStatus = this.lastSemesterSimulationData?.simulation_accord.total_by_status;
      if(this.lastSemesterSimulationData?.simulation_accord){
        const accord = totalByStatus.OK + totalByStatus.OK_FIN + totalByStatus.OK_EXP;
        return `${percentage(accord, this.lastSemesterSimulationData?.simulation_accord.total, 0)}`;
      }
      return `${percentage(0, 0,0)}`;
    },
    user: function () {
      let user = this.$store.getters.getUser ?? {name: '', firstname: ''};
      return `${user.firstname} ${user.name}`;
    },
    dailyMessage() {
      return this.$store.getters.getMessage;
    },
  },
  methods: {
    totalStatusDisplay(data) {
      // eslint-disable-next-line no-unused-vars
      let total = Object.keys(data.total_by_status).filter((value) => {
        return !this.doNotDisplay.includes(data.status_langs[value])
      })
      if(!total.length){
        return 0;
      }
      let accumulator = 0;
      total.forEach((value) => {
        accumulator += data.total_by_status[value];
      })
      return accumulator;
    },
  },
  async mounted() {
    this.simulationData = (await fundingService.getFundingStats()).data ?? {};
    this.lastSemesterSimulationData = (await fundingService.getLastSemesterFundingStats()).data ?? {};
    const today = new Date();
    this.firstDay = reverseDateFormat(new Date(today.getFullYear(), today.getMonth() - 3, 1)).replaceAll('/', '');
    this.lastDay = reverseDateFormat(new Date(today.getFullYear(), today.getMonth(), 0)).replaceAll('/', '');
    this.complianceStats = (await UploadService.getComplianceStats(this.firstDay, this.lastDay)).data.compliances;
    //this.simulationData.compliances = {notClickable: true, overrideColor: true, ...this.complianceStats};
    this.chartData.datasets = [
      {
        data: this.simulationAccord,
        backgroundColor: ['#3FC0F0', '#EA4B58', '#808080', '#23A638']
      }
    ];
  }
};
</script>

<style scoped>
.disable-click {
  pointer-events: none;
}
.card-header-white {
  background-color: white;
  font-weight: 600;
}
.leaser-color {
  background: #6f42c1 !important;
}

.card-header-none {
  background-color: transparent;
  font-weight: 600;
}

.spinner-border {
  animation-duration: 2s;
}

.no-data{
  text-align-last: center;
  background: black;
  border-radius: 502px;
  width: 40%;
  margin: auto;
}

.img-rounded-avatar {
  height: 50px;
  border-radius: 5px;
}

.avatar-name {
  margin-left: 15px;
  align-self: center;
  margin-top: 10px;
}

h3 {
  text-transform: uppercase;
  font-weight: 700;
}

.flex {
  display: flex;
}

.container-chart {
  margin-top: 10px;
  padding-top: 10px;
}

.progress-steps {
  font-family: "Open Sans", sans-serif;
}

.progress-steps > div {
  margin-left: 39px;
  position: relative;
}

.progress-steps > div.hidden {
  display: none;
}

.progress-steps > div.active > h1::before {
  border: none;
}

.progress-steps > div.complete > h1 {
  color: gray;
  font-weight: 600;
}

.progress-steps > div.complete > h1 > .number {
  background: #EE7202;
  color: #FFF;
}

.progress-steps > div:last-child > h1::before {
  border: none;
}

.progress-steps > div > h1 {
  font-size: 1em;
  margin-left: -39px;
  color: #222;
  font-weight: 600;
}

.progress-steps > div > h1 > .number {
  background: #16C4C7;
  color: #FFF;
}

.progress-steps > div > h1::before {
  content: "";
  position: absolute;
  border-left: solid 4px #D4D4D1;
  width: 4px;
  height: 100%;
  top: 33px;
  left: -24px;
}

.progress-steps > div > h1 > .number {
  border: 4px solid #D4D4D4;
  border-radius: 50%;
  width: 33px;
  height: 33px;
  display: inline-block;
  text-align: center;
  line-height: 25px;
}

.ocean {
  height: 19px; /* change the height of the waves here */
  width: 100%;
  position: fixed;
  bottom: 25px;
  left: 0;
  right: 0;
  overflow: hidden;
  z-index: 3;
}

.oceanbottom {
  height: 28px; /* change the height of the waves here */
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  background-color: rgba(8, 143, 143, 0.8);
  z-index: 3;
}

.wave {
  background: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyBpZD0iQ2FscXVlXzIiIGRhdGEtbmFtZT0iQ2FscXVlIDIiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgdmlld0JveD0iMCAwIDgwMCAyMC43Ij4KICA8ZGVmcz4KICAgIDxzdHlsZT4KICAgICAgLmNscy0xIHsKICAgICAgICBmaWxsOiAjMDg4ZjhmOwogICAgICB9CiAgICA8L3N0eWxlPgogIDwvZGVmcz4KICA8ZyBpZD0iQ2FscXVlXzEtMiIgZGF0YS1uYW1lPSJDYWxxdWUgMSI+CiAgICA8cGF0aCBjbGFzcz0iY2xzLTEiIGQ9Im04MDAsMTIuNjRDNjQ0LjUsMTIuNjQsNTk1LjEtLjAzLDM5NC41LDAsMTk0LjUsMCwxNDQuNSwxMi42NCwwLDEyLjY0djguMDZoODAwdi04LjA2WiIvPgogIDwvZz4KPC9zdmc+Cg==");
  background-repeat: repeat-x;
  background-size: 600px;
  position: absolute;
  width: 200%;
  height: 100%;
  animation: wave 5s -3s linear infinite;
  transform: translate3d(0, 0, 0);
  opacity: 0.5;
  z-index: 3;
}

.wave:nth-of-type(2) {
  bottom: 0;
  animation: wave 10s linear reverse infinite;
  opacity: 0.4;
  z-index: 3;
}

.wave:nth-of-type(3) {
  bottom: 0;
  animation: wave 7s -1s linear infinite;
  opacity: 0.3;
  z-index: 3;
}

@keyframes wave {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(-10%);
  }
  100% {
    transform: translateX(-15%);
  }
}


.boat {
  animation: rotate ease 20s;
  animation-iteration-count: infinite;
  z-index: 1;
  margin-left: 50px;
  margin-right: 50px;
  position: fixed;
  bottom: 23px;
  overflow: hidden;
}

.boat-wrapper {
  transition: padding-left 20s;
}

.boat-wrapper:hover {
  padding-left: 80%;
}

.margin-boat {
  margin-bottom: 50px;
}

.margin-50 {
  margin-left: 50px;
}

.margin-20 {
  margin-left: 20px;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  20% {
    transform: rotate(-6deg);
  }
  40% {
    transform: rotate(-2deg);
  }
  60% {
    transform: rotate(2deg);
  }
  80% {
    transform: rotate(4deg);
  }
  100% {
    transform: rotate(1deg);
  }
}

</style>
