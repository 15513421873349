<template>
  <form v-if="show" id="testlaBar" class="form-inline" v-bind:class="{ short: !hasCustomer && !modifiable }">
    <div class="form-group d-inline-block tesla">
      <p><label>Type Contrat</label></p>
      <select
        class="form-control t-1-4e"
        @change="emitOption('recalculateFromTeslaChanges', 'fk_type_contrat', $event.target.value)"
        :disabled="!modifiable"
      >
        <option v-for="(name, value) in contractTypes" :key="value" :selected="value === funding.fk_type_contrat" :value="value">{{ name }}</option>
      </select>
    </div>
    <div class="form-group d-inline-block tesla">
      <p><label>Montant financé HT</label></p>
      <input
        type="text"
        class="form-control"
        placeholder="0.00"
        @blur="debounceInput('montant_total_finance', parseFloat($event.target.value))"
        :value="(Math.round(funding.montant_total_finance * 100) / 100).toFixed(2)"
        :readonly="!modifiable"
      />
    </div>
    <div class="form-group d-inline-block dropdown w-duree">
      <p><label>Durée</label></p>
      <select
        class="form-control"
        @change="emitOption('recalculateFromTeslaChanges', 'duree', parseInt($event.target.value))"
        v-model="funding.duree"
        :disabled="!modifiable"
      >
        <option v-for="(num, index) in duration" :key="index" :value="index" :selected="index === funding.duree">{{ index }}</option>
      </select>
    </div>
    <div class="form-group d-inline-block dropdown">
      <p><label>Période</label></p>
      <select class="form-control" @change="changePeriodicity($event.target.value)" :disabled="!modifiable">
        <option v-for="(name, value) in contractPeriodicity" :selected="value === funding.opt_periodicite" :key="value" :value="value">
          {{ name }}
        </option>
      </select>
    </div>
    <div class="form-group focus d-inline-block text-right tesla">
      <p><label>Loyer HT</label></p>
      <input type="text" class="form-control loyer" placeholder="0.00"
             @blur="debounceInput('echeance', parseFloat($event.target.value))"
             :value="(this.funding.echeance).toFixed(2)"
             :readonly="!modifiable"/>
    </div>
    <div class="form-group btn-action d-inline-block" v-if="hasCustomer || modifiable">
      <el-popover placement="top-start" :width="250" :visible="showPopUp">
        <h5 :style="{ color: this.statusFunding[this.status]?.colorhex }" class="initial-align break">
          Votre simulation est en {{ this.statusFunding[this.status]?.text }}.
        </h5>
        <p class="initial-align break">Vous pouvez actuliser votre demande avec les nouvelles informations saisies</p>
        <button class="btn btn-primary max-content mt-2" v-on:click="reload">Abondonner les modifications</button>
        <template #reference>
          <template v-if="modified && statusIsValid">
            <button
              class="btn btn-warning cursor-default"
              type="button"
              @click="
                this.emitOption('sendFunding');
              "
            >
              <span class="ifa white"><fa icon="circle-notch"></fa></span>
              <span class="long white">Envoyer<br />les modifications</span>
            </button>
          </template>
          <template v-else>
            <el-popover v-if="this.status === 'WAIT' || this.status === 'WAIT_MODIF'"
                        placement="top-start"
                        title="En étude"
                        :width="200"
                        trigger="hover"
                        content="Simulatio en étude"
            >
              <template #reference>
                <button class="btn btn-blue cursor-pointer" type="button">
                  <span class="ifa" v-if="loadingState"><fa icon="circle-notch" class="fa-spin"></fa></span>
                  <span class="ifa" v-else><fa icon="stopwatch"></fa></span>
                </button>
              </template>
            </el-popover>
            <el-popover v-else-if="this.status === 'WAIT_LEASER'"
                        placement="top-start"
                        title="En attente"
                        :width="200"
                        trigger="hover"
                        content="En attente d'éléments"
            >
              <template #reference>
                <button class="btn btn-warning cursor-default" type="button">
                  <span class="ifa"><fa icon="question-circle"></fa></span>
                </button>
              </template>
            </el-popover>
            <el-popover v-else-if="this.status === 'WAIT_AP'" @click="this.emitOption('sendFunding');"
                        placement="top-start"
                        title="Accord"
                        :width="200"
                        trigger="hover"
                        content="Accord de principe"
            >
              <template #reference>
                <button class="btn btn-success cursor-default" type="button">
                  <span class="ifa"><fa icon="handshake"></fa></span>
                </button>
              </template>
            </el-popover>
            <el-popover v-else-if="statusIsValid" @click="this.emitOption('sendFunding');"
                        placement="top-start"
                        title="Accord"
                        :width="200"
                        trigger="hover"
                        content="Simulation en Accord"
            >
              <template #reference>
                <button class="btn btn-success cursor-default" type="button">
                  <span class="ifa"><fa icon="thumbs-up"></fa></span>Accord
                </button>
              </template>
            </el-popover>
            <el-popover v-else-if="this.status === 'KO'"
                        placement="top-start"
                        title="Refus"
                        :width="200"
                        trigger="hover"
                        content="Simulation Refusée"
            >
              <template #reference>
                <button class="btn btn-danger cursor-default" type="button">
                  <span class="ifa"><fa icon="ban"></fa></span>
                </button>
              </template>
            </el-popover>
            <el-popover v-else-if="this.status === 'OK_EXP'"
                        placement="top-start"
                        title="Expirée"
                        :width="200"
                        trigger="hover"
                        content="Simulation expirée"
            >
              <template #reference>
                <button class="btn btn-grey cursor-default" type="button" >
                  <span class="ifa"><fa icon="ban"></fa></span>
                </button>
              </template>
            </el-popover>
            <el-popover v-else
                placement="top-start"
                title="Envoyer"
                :width="200"
                trigger="hover"
                content="Envoyer la simulation"
            >
              <template #reference>
                <button ref="popoverRef" class="btn btn-blue cursor-pointer" type="button" :disabled="loadingState" @click="this.emitOption('sendFunding');">
                  <span class="ifa" v-if="loadingState"><fa icon="circle-notch" class="fa-spin"></fa></span>
                  <span class="ifa" v-else><fa icon="paper-plane"></fa></span>
                </button>
              </template>
            </el-popover>
          </template>
        </template>
      </el-popover>
    </div>
  </form>
</template>

<script>
import { ref } from 'vue';
import statusFunding from '../class/funding.js';
import Funding from "@/class/funding";

export default {
  name: 'TeslaBar',
  props: {
    fu: {
      type: Object
    },
    typeContrat: {
      type: Array
    },
    status: {
      type: String,
      default: 'NEW'
    },
    duration: {
      type: Object
    },
    contractPeriodicity: {
      type: Object
    },
    modified: {
      type: Boolean
    },
    modifiable: {
      type: Boolean
    },
    loadingState: {
      type: Boolean
    }
  },
  data() {
    return {
      popoverRef: ref(),
      contractTypes: null,
      funding: null,
      hasCustomer: null,
      echeance: null,
      default_montant_total_finance: 0,
      show: false,
      statusFunding: statusFunding.status
    };
  },
  watch: {
    fu(newValue) {
      this.funding = newValue;
    }
  },
  methods: {
    emitOption(method, name = null, value = null) {
      if (this.modifiable) {
        this.$emit(method, name, value);
      }
    },
    debounceInput(field, value) {
      this.emitOption('recalculateFromTeslaChanges', field, value);
    },
    reload() {
      this.$router.go();
    },
    changePeriodicity(value) {
      this.$emit('recalculateFromTeslaChanges', 'opt_periodicite_wait', value);
    },
    raz() {
      this.funding.montant_total_finance = this.default_montant_total_finance;
    }
  },
  computed: {
    statusIsValid() {
      return Funding.validStatus.includes(this.status);
    },
    showPopUp() {
      return this.modified && (this.status === 'WAIT_AP' || this.statusIsValid);
    }
  },
  mounted() {
    this.funding = this.fu;
    this.hasCustomer = this.funding.fk_soc > 0;
    this.echeance = this.funding.echeance;
    this.contractTypes = this.typeContrat;
    this.show = true;
  }
};
</script>

<style scoped>
.label-right {
  display: block;
  text-align: right !important;
}
#testlaBar {
  background: #464b5f;
  color: #fff;
  padding: 8px;
  min-height: 100px;
  right: 15px;
  bottom: 15px;
}

#testlaBar.short {
  width: auto;
}

.max-content {
  width: max-content;
}

.white {
  color: white;
}

.form-control:disabled {
  background-color: transparent;
}

.break {
  word-break: break-word;
}

.initial-align {
  text-align: initial;
}

.tesla p {
  margin: 0 !important;
}

.dropdown p {
  margin: 0 !important;
}

.tesla input {
  color: #464b5f !important;
}

.tesla input:read-only {
  color: white;
}

.tesla input {
  max-width: 200px;
}

.form-group {
  height: 70px;
  padding: 0 12px;
}

.form-group label {
  font-weight: 700;
  color: white;
}

.form-group.focus {
  border-radius: 6px;
  min-width: 200px;
}

.separator {
  border-right: 1px solid #1e3e51;
}

select,
select:active,
select:focus,
input[type='text'] {
  color: white;
  border: 0;
}

select input,
select:active,
select:focus,
input[type='text'] {
  color: white;
  border: 0;
}

input[type='text'] {
  font-size: 1.5em;
  height: 1.8em;
}

select,
select:active,
select:focus {
  background: transparent;
  cursor: pointer;
}

select option {
  color: #fff;
  background-color: #082c42;
  margin: 0;
  cursor: pointer;
  font-size: 16px;
  padding: 0;
}

.form-inline label {
  justify-content: flex-start;
  text-align: left;
  font-size: 1.3em;
  padding-bottom: 5px;
}

.btn-action {
  position: absolute;
  right: 0;
}

.btn-action .btn {
  position: relative;
  background-color: #1e3e51;
  border-radius: 6px;
  margin-left: 15px;
  padding-left: 60px;
  height: 100%;
  color: #fff;
  min-width: 220px;
  font-size: 1.7em;
}

.btn-action .btn.btn-warning {
  background-color: #bb9000;
}

.btn-action .btn.btn-grey {
  background-color: #808080;
}

.btn-action .btn.btn-success {
  background-color: #028129;
}

.btn-action .btn.btn-danger {
  background-color: #810202;
}

.btn-action .btn .long {
  font-size: 0.75em;
  display: inline-block;
}

.btn-action .btn span.ifa {
  background-color: #ab88bc;
  display: inline-block;
  border-radius: 6px;
  position: absolute;
  left: 0;
  top: 0;
  padding: 15px 20px;
  font-size: 1em;
  height: 100%;
}

.btn-action .btn.btn-warning span.ifa {
  background-color: #a1681d;
}

.btn-action .btn.btn-success span.ifa {
  background-color: #1da142;
}

.btn-action .btn.btn-danger span.ifa {
  background-color: #a11d1d;
}

.btn-action .btn.btn-grey span.ifa{
  background-color: #a0a0a0;
}

.btn.btn-blue {
  background-color: #9569ab;
}

button .ifa {
  color: white;
}

.loyer {
  width: 175px !important;
}

.w-duree {
  width: 10%;
}

.w-duree select {
  width: 100% !important;
}
</style>
